import React from 'react';
import css from './Breadcrumb.css';

const Breadcrumb = props => {
    const {
        paths,
        urlPrefix
    } = props;

    const replaceUmlauts = str => {
        return str
            .replace(/ä/g, 'ae')
            .replace(/ö/g, 'oe')
            .replace(/ü/g, 'ue')
            .replace(/ß/g, 'ss');
    };

    const addUmlauts = str => {
        return str
            .replace(/ae/g, 'ä')
            .replace(/oe/g, 'ö')
            .replace(/ue/g, 'ü');
    };

    const formatPathLabel = (paths, index) => {
        return paths.slice(0, index + 1)
            .map(path => {
                return path.urlPath
                    ? encodeURIComponent(path.urlPath)
                    : encodeURIComponent(replaceUmlauts(path.label.toLowerCase()).replace(/ /g, '-'));
            })
            .join('/');
    };


    const capitalizeAfterHyphen = str => {
        return addUmlauts(str).split('-').map(word => capitalizeFirstLetter(word)).join(' ');
    };

    const capitalizeFirstLetter = str => {
        return str.charAt(0).toUpperCase() + str.slice(1);
    };

    return (
        <div className={css.breadcrumb} aria-label='breadcrumb'>
            {paths.map((path, index) => (
                <React.Fragment key={index}>
                    {index > 0 &&
                        <svg className={css.breadcrumbSeparator} xmlns="http://www.w3.org/2000/svg" width="16" height="18"
                             viewBox="0 0 16 18" fill="none">
                            <path
                                d="M3.66666 1.5L10.8133 8.64667C10.8598 8.69304 10.8966 8.74812 10.9217 8.80875C10.9469 8.86938 10.9598 8.93437 10.9598 9C10.9598 9.06563 10.9469 9.13062 10.9217 9.19125C10.8966 9.25188 10.8598 9.30696 10.8133 9.35333L3.66666 16.5"
                                stroke="#B2B2B2" stroke-width="1.5" stroke-linecap="round"
                                stroke-linejoin="round" />
                        </svg>
                    }
                    <li className={css.breadcrumbItem}>
                        {
                            path.url ?
                                <a href={path.url} className={css.breadcrumbItemLink}>{capitalizeAfterHyphen(path.label)}</a> :
                                urlPrefix ?
                                    <a href={`${urlPrefix}/${formatPathLabel(paths, index)}`} className={css.breadcrumbItemLink}>{capitalizeAfterHyphen(path.label)}</a> :
                                    <span>{capitalizeAfterHyphen(path.label)}</span>
                        }
                    </li>
                </React.Fragment>
            ))}
        </div>
    );
};

export default Breadcrumb;
