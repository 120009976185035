import React from 'react';
import css from './PopularDisciplines.css';
import { compose } from 'redux';
import { injectIntl } from 'react-intl';

const PopularDisciplines = props => {
    const {
        address,
        bounds,
        listingType,
        pub_activities,
        intl,
        loc
    } = props;
    const link = `/s?address=${address}&bounds=${bounds}&origin=${loc}&listingType=${listingType}${pub_activities ? `&pub_activities=has_any%3A${pub_activities.replace(',', '%2C')}` : ''}`;

    const popularDisciplinesData = [
        { title: intl.formatMessage({ id: 'PopularDisciplines.title.terrainRiding' }), image: 'https://horsedeal.imgix.net/static/landingPage/ausreiten.webp', discipline: 'terrainRiding' },
        { title: intl.formatMessage({ id: 'PopularDisciplines.title.jumping' }), image: 'https://horsedeal.imgix.net/static/landingPage/springreiten.webp', discipline: 'jumping' },
        { title: intl.formatMessage({ id: 'PopularDisciplines.title.dressage' }), image: 'https://horsedeal.imgix.net/static/landingPage/dressurreiten.webp', discipline: 'dressage' },
        { title: intl.formatMessage({ id: 'PopularDisciplines.title.western' }), image: 'https://horsedeal.imgix.net/static/landingPage/westernreiten.webp', discipline: 'western' },
        { title: intl.formatMessage({ id: 'PopularDisciplines.title.versatility' }), image: 'https://horsedeal.imgix.net/static/landingPage/vielseitigkeit.webp', discipline: 'versatility' }
    ];

    return (
        <div className={css.popularDisciplinesContainer}>
            <a href={`${link}&pub_desiredDisciplines=${popularDisciplinesData[0].discipline}`} className={css.popularDisciplines} style={{ '--backgroundImage': `url(${popularDisciplinesData[0].image})` }}>
                <h3 className={css.popularDisciplinesTitle}>{popularDisciplinesData[0].title}</h3>
            </a>
            <div className={css.smallDisciplinesContainer}>
                {popularDisciplinesData.slice(1).map((discipline, index) => (
                    <a key={index} href={`${link}&pub_desiredDisciplines=${discipline.discipline}`} className={css.popularDisciplines} style={{ '--backgroundImage': `url(${discipline.image})` }}>
                        <h3 className={css.popularDisciplinesTitle}>{discipline.title}</h3>
                    </a>
                ))}
            </div>
        </div>
    );
};

export default compose(injectIntl)(PopularDisciplines);
