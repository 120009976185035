import React, { useState } from 'react';
import { injectIntl } from 'react-intl';
import css from './HorseDealCreateAccountTeaser.css';
import { FormattedMessage } from 'react-intl';
import { SignupForm } from '../../forms';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { NamedLink } from '../index';
import { isLocalhost } from '../../util/urlHelpers';
import { signup } from '../../ducks/Auth.duck';
import Followers from '../../containers/LandingPage/common/Followers';

const windowDefined = typeof window !== 'undefined';

const HorseDealCreateAccountTeaser = props => {
    const {
        title,
        participationName,
        currentUser,
        address,
        bounds,
        origin,
        listingType,
        pub_activities,
        link,
        tab,
        intl,
        signup
    } = props;
    const userIsDefined = currentUser && currentUser.id;
    const currentDate = new Date();
    const lastDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);
    const lastDayFormatted = lastDayOfMonth.toLocaleDateString('de-DE', { day: '2-digit', month: '2-digit', year: 'numeric' });
    const isSignupRider = tab === 'signup-rider';
    const queryParams = [];
    const [userType, setUserType] = useState('rider');

    const handleSubmitDecorator = (values, callback) => {
        if (isLocalhost()) return callback(values);

        if (windowDefined && window.checkRecaptchaBeforeSubmit) {
            window.checkRecaptchaBeforeSubmit(() => callback(values));
        } else {
            callback(values);
        }
    };

    const handleUserTypeChange = event => {
        setUserType(event.target.value);
    };

    if (address) {
        queryParams.push(`address=${encodeURIComponent(address)}`);
    }

    queryParams.push(`bounds=${encodeURIComponent(bounds)}`);

    if (origin) {
        queryParams.push(`origin=${encodeURIComponent(origin)}`);
    }
    if (listingType) {
        queryParams.push(`listingType=${encodeURIComponent(listingType)}`);
    }
    if (pub_activities) {
        queryParams.push(`pub_activities=has_any%3A${encodeURIComponent(pub_activities.replace(',', '%2C'))}`);
    }

    const queryString = queryParams.length ? `?${queryParams.join('&')}` : '';

    const render = () => {
        return (
            <div className={css.createAccountWrapper}>
                <div className={css.createAccountColumn}>
                    <h2 className={css.createAccountTitle}>
                        <span className={css.createAccountTitleColor}>{title} </span>
                        {intl.formatMessage({ id: 'HorseDealCreateAccountTeaser.title' })}
                    </h2>
                    <div className={css.benefitsWrapper}>
                        <div className={css.benefitsRow}>
                            <div className={css.benefitsColumn}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48"
                                     viewBox="0 0 48 48" fill="none">
                                    <g clipPath="url(#clip0_3147_139305)">
                                        <path
                                            d="M1.5 6C1.5 6.59095 1.6164 7.17611 1.84254 7.72208C2.06869 8.26804 2.40016 8.76412 2.81802 9.18198C3.23588 9.59984 3.73196 9.93131 4.27792 10.1575C4.82389 10.3836 5.40905 10.5 6 10.5C6.59095 10.5 7.17611 10.3836 7.72208 10.1575C8.26804 9.93131 8.76412 9.59984 9.18198 9.18198C9.59984 8.76412 9.93131 8.26804 10.1575 7.72208C10.3836 7.17611 10.5 6.59095 10.5 6C10.5 5.40905 10.3836 4.82389 10.1575 4.27792C9.93131 3.73196 9.59984 3.23588 9.18198 2.81802C8.76412 2.40016 8.26804 2.06869 7.72208 1.84254C7.17611 1.6164 6.59095 1.5 6 1.5C5.40905 1.5 4.82389 1.6164 4.27792 1.84254C3.73196 2.06869 3.23588 2.40016 2.81802 2.81802C2.40016 3.23588 2.06869 3.73196 1.84254 4.27792C1.6164 4.82389 1.5 5.40905 1.5 6Z"
                                            stroke="#8F2593" strokeWidth="2.5"
                                            strokeLinecap="round" strokeLinejoin="round" />
                                        <path
                                            d="M1.5 24C1.5 24.5909 1.6164 25.1761 1.84254 25.7221C2.06869 26.268 2.40016 26.7641 2.81802 27.182C3.23588 27.5998 3.73196 27.9313 4.27792 28.1575C4.82389 28.3836 5.40905 28.5 6 28.5C6.59095 28.5 7.17611 28.3836 7.72208 28.1575C8.26804 27.9313 8.76412 27.5998 9.18198 27.182C9.59984 26.7641 9.93131 26.268 10.1575 25.7221C10.3836 25.1761 10.5 24.5909 10.5 24C10.5 23.4091 10.3836 22.8239 10.1575 22.2779C9.93131 21.732 9.59984 21.2359 9.18198 20.818C8.76412 20.4002 8.26804 20.0687 7.72208 19.8425C7.17611 19.6164 6.59095 19.5 6 19.5C5.40905 19.5 4.82389 19.6164 4.27792 19.8425C3.73196 20.0687 3.23588 20.4002 2.81802 20.818C2.40016 21.2359 2.06869 21.732 1.84254 22.2779C1.6164 22.8239 1.5 23.4091 1.5 24Z"
                                            stroke="#8F2593" strokeWidth="2.5"
                                            strokeLinecap="round" strokeLinejoin="round" />de
                                        <path
                                            d="M1.5 42C1.5 42.5909 1.6164 43.1761 1.84254 43.7221C2.06869 44.268 2.40016 44.7641 2.81802 45.182C3.23588 45.5998 3.73196 45.9313 4.27792 46.1575C4.82389 46.3836 5.40905 46.5 6 46.5C6.59095 46.5 7.17611 46.3836 7.72208 46.1575C8.26804 45.9313 8.76412 45.5998 9.18198 45.182C9.59984 44.7641 9.93131 44.268 10.1575 43.7221C10.3836 43.1761 10.5 42.5909 10.5 42C10.5 41.4091 10.3836 40.8239 10.1575 40.2779C9.93131 39.732 9.59984 39.2359 9.18198 38.818C8.76412 38.4002 8.26804 38.0687 7.72208 37.8425C7.17611 37.6164 6.59095 37.5 6 37.5C5.40905 37.5 4.82389 37.6164 4.27792 37.8425C3.73196 38.0687 3.23588 38.4002 2.81802 38.818C2.40016 39.2359 2.06869 39.732 1.84254 40.2779C1.6164 40.8239 1.5 41.4091 1.5 42Z"
                                            stroke="#8F2593" strokeWidth="2.5"
                                            strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M16.5 6H46.5" stroke="#8F2593" strokeWidth="2.5"
                                              strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M16.5 24H46.5" stroke="#8F2593" strokeWidth="2.5"
                                              strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M16.5 42H46.5" stroke="#8F2593" strokeWidth="2.5"
                                              strokeLinecap="round" strokeLinejoin="round" />
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_3147_139305">
                                            <rect width="48" height="48" fill="white" />
                                        </clipPath>
                                    </defs>
                                </svg>
                            </div>
                            <div className={css.benefitsColumn}>
                                {intl.formatMessage({ id: 'HorseDealCreateAccountTeaser.benefitsColumn1' }, { participationName: participationName })}
                            </div>
                        </div>
                        <div className={css.benefitsRow}>
                            <div className={css.benefitsColumn}>
                                <svg xmlns='http://www.w3.org/2000/svg' width='48' height='48'
                                     viewBox='0 0 48 48' fill='none'>
                                    <path
                                        d='M24.1514 44.2157L5.2046 23.6926C3.53393 21.9309 2.43844 19.7029 2.06352 17.3042C1.6886 14.9054 2.05196 12.4494 3.10539 10.262C3.85212 8.62917 4.98736 7.20411 6.4119 6.11128C7.83645 5.01846 9.50695 4.29114 11.2774 3.99287C13.0479 3.6946 14.8645 3.83445 16.5685 4.40022C18.2724 4.96599 19.8119 5.94045 21.0524 7.23845L24.1514 10.4533L27.2449 7.24114C28.485 5.9442 30.0237 4.97057 31.7268 4.40528C33.4298 3.83999 35.2453 3.70023 37.0148 3.9982C38.7843 4.29617 40.4539 5.02281 41.8779 6.11467C43.3019 7.20653 44.4369 8.63041 45.1838 10.262C46.2382 12.4487 46.602 14.9046 46.2271 17.3031C45.8521 19.7016 44.7561 21.9292 43.0846 23.6899L24.1514 44.2157Z'
                                        stroke='#8F2593' strokeWidth='2.5' strokeLinecap='round'
                                        strokeLinejoin='round' />
                                </svg>
                            </div>
                            <div className={css.benefitsColumn}>
                                {intl.formatMessage({ id: 'HorseDealCreateAccountTeaser.benefitsColumn2' })}
                            </div>
                        </div>
                        <div className={css.benefitsRow}>
                            <div className={css.benefitsColumn}>
                                <svg xmlns='http://www.w3.org/2000/svg' width='48' height='48'
                                     viewBox='0 0 48 48' fill='none'>
                                    <path
                                        d='M24 45C35.598 45 45 35.598 45 24C45 12.402 35.598 3 24 3C12.402 3 3 12.402 3 24C3 35.598 12.402 45 24 45Z'
                                        stroke='#8F2593' strokeWidth='2.5' strokeLinecap='round'
                                        strokeLinejoin='round' />
                                    <path d='M24 24V16.5' stroke='#8F2593' strokeWidth='2.5'
                                          strokeLinecap='round' strokeLinejoin='round' />
                                    <path d='M24 24L33.374 33.376' stroke='#8F2593'
                                          strokeWidth='2.5' strokeLinecap='round'
                                          strokeLinejoin='round' />
                                </svg>
                            </div>
                            <div className={css.benefitsColumn}>
                                {intl.formatMessage({ id: 'HorseDealCreateAccountTeaser.benefitsColumn3' })}
                            </div>
                        </div>
                        <div className={css.benefitsRow}>
                            <div className={css.benefitsColumn}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48"
                                     viewBox="0 0 48 48" fill="none">
                                    <g clipPath="url(#clip0_3147_139322)">
                                        <path
                                            d="M43.5 22.5H4.5V43.5C4.5 44.2956 4.81607 45.0587 5.37868 45.6213C5.94129 46.1839 6.70435 46.5 7.5 46.5H40.5C41.2956 46.5 42.0587 46.1839 42.6213 45.6213C43.1839 45.0587 43.5 44.2956 43.5 43.5V22.5Z"
                                            stroke="#8F2593" strokeWidth="2.5"
                                            strokeLinecap="round" strokeLinejoin="round" />
                                        <path
                                            d="M43.5 13.5H4.5C3.70435 13.5 2.94129 13.8161 2.37868 14.3787C1.81607 14.9413 1.5 15.7044 1.5 16.5V21C1.5 21.3978 1.65804 21.7794 1.93934 22.0607C2.22064 22.342 2.60218 22.5 3 22.5H45C45.3978 22.5 45.7794 22.342 46.0607 22.0607C46.342 21.7794 46.5 21.3978 46.5 21V16.5C46.5 15.7044 46.1839 14.9413 45.6213 14.3787C45.0587 13.8161 44.2956 13.5 43.5 13.5Z"
                                            stroke="#8F2593" strokeWidth="2.5"
                                            strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M22.5 13.5C15.872 13.5 9 8.128 9 1.5"
                                              stroke="#8F2593" strokeWidth="2.5"
                                              strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M9 1.5C15.628 1.5 22.5 6.872 22.5 13.5"
                                              stroke="#8F2593" strokeWidth="2.5"
                                              strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M25.5 13.5C32.128 13.5 39 8.128 39 1.5"
                                              stroke="#8F2593" strokeWidth="2.5"
                                              strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M39 1.5C32.372 1.5 25.5 6.872 25.5 13.5"
                                              stroke="#8F2593" strokeWidth="2.5"
                                              strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M19.5 13.5H28.5V46.5H19.5V13.5Z" stroke="#8F2593"
                                              strokeWidth="2.5" strokeLinecap="round"
                                              strokeLinejoin="round" />
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_3147_139322">
                                            <rect width="48" height="48" fill="white" />
                                        </clipPath>
                                    </defs>
                                </svg>
                            </div>
                            <div className={css.benefitsColumn}>
                                {intl.formatMessage({ id: 'HorseDealCreateAccountTeaser.benefitsColumn4' })}
                            </div>
                        </div>
                    </div>
                    <Followers rootClassName={css.followersHolder}/>
                </div>
                <div className={css.createAccountColumn}>

                    {!userIsDefined ? (
                        <div className={css.createAccountColumnContent}>
                            <div className={css.voucherNotification}>
                                <div className={css.voucherIcon}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26"
                                         viewBox="0 0 26 26" fill="none">
                                        <path
                                            d="M9 13.7962L10.8375 16.404C10.9789 16.6152 11.2137 16.7451 11.4678 16.7527C11.7219 16.7603 11.964 16.6447 12.1178 16.4422L18 9M13 25C19.6274 25 25 19.6274 25 13C25 6.37258 19.6274 1 13 1C6.37258 1 1 6.37258 1 13C1 19.6274 6.37258 25 13 25Z"
                                            stroke="#1E9F55" strokeWidth="1.5"
                                            strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>
                                </div>
                                <div>
                                    <p className={css.voucherTitle}>
                                        <FormattedMessage
                                            id="HorseDealCreateAccountTeaser.SectionRegister.voucherDetected"
                                            defaultMessage="Gutschein erkannt" />
                                    </p>
                                    <p className={css.voucherDescription}>
                                        <FormattedMessage
                                            id="HorseDealCreateAccountTeaser.SectionRegister.voucherText"
                                            defaultMessage={`Registriere dich bis zum {date} und erhalte einen CHF 10.- Gutschein von Reitsport.ch geschenkt.`}
                                            values={{
                                                date: lastDayFormatted,
                                            }}
                                        />
                                    </p>
                                </div>
                            </div>

                            <div className={css.userTypeRadio}>
                                <label className={css.radioLabel}>
                                    <input
                                        type="radio"
                                        name="userType"
                                        value="rider"
                                        checked={userType === 'rider'}
                                        onChange={handleUserTypeChange}
                                        className={css.radioInput}
                                    />
                                    <FormattedMessage id="SignupSettings.userTypeRider" defaultMessage="Pferd finden" />
                                </label>
                                <label className={css.radioLabel}>
                                    <input
                                        type="radio"
                                        name="userType"
                                        value="horseowner"
                                        checked={userType === 'horseowner'}
                                        onChange={handleUserTypeChange}
                                        className={css.radioInput}
                                    />
                                    <FormattedMessage id="SignupSettings.userTypeHorseowner" defaultMessage="Pferdemensch finden" />
                                </label>
                            </div>

                            <SignupForm
                                className={css.registrationForm}
                                onSubmit={values => handleSubmitDecorator(values, () => {
                                    const params = {
                                        ...values,
                                        firstName: values.fname,
                                        lastName: values.lname,
                                        privateData: {
                                            ...values.privateData,
                                            registeredViaLandingPage: true,
                                        },
                                    };

                                    signup(params);
                                })}
                                initialValues={{ userType: userType }}
                            />
                        </div>
                    ) : (

                        link ? (
                            <a href={link} className={css.subLinkPrimary}>
                                <FormattedMessage id="SectionRegister.startButton"
                                                  defaultMessage="Los geht's!" />
                            </a>
                        ) : (
                            <NamedLink
                                className={css.subLinkPrimary}
                                name="SearchPage"
                                to={{
                                    search: queryString,
                                }}
                            >
                                <FormattedMessage id="SectionRegister.startButton" defaultMessage="Los geht's!" />
                            </NamedLink>
                        )
                    )}
                </div>
            </div>
        );
    };

    return render();
};

const mapDispatchToProps = dispatch => ({
    signup: params => dispatch(signup(params)),
});

export default compose(
    injectIntl,
    connect(null, mapDispatchToProps)
)(HorseDealCreateAccountTeaser);
