import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { FormattedMessage, injectIntl, intlShape } from '../../../../util/reactIntl';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { string } from 'prop-types';
import { propTypes } from '../../../../util/types';
import { StaticPage, TopbarContainer } from '../../../index';
import {
    LayoutSingleColumn,
    LayoutWrapperTopbar,
    LayoutWrapperMain,
    LayoutWrapperFooter,
    Footer,
    Breadcrumb,
    AccordionItems,
    generateAccordionTableOfContents,
    HorseDealAppTeaser,
    HorseDealCreateAccountTeaser,
} from '../../../../components';

import css from '../LandingPage.css';
import { redirectToURLWithModalState } from '../../../../components/Topbar/Topbar.helpers';
import MagnifyIcon from '../../../ListingPage/Icons/MagnifyIcon';
import { useIsMobile } from '../../../../hooks/useIsMobile';
import { useListings } from '../../../../hooks/useListings';
import config from '../../../../config';
import { ReviewsWidget } from '../../../AuthenticationPage/ReviewsWidget';
import { getCurrentUserLocation } from '../../../../util/localStorage';

const PflegebeteiligungFindenPageComponent = props => {
    const {
        intl,
        location,
        history,
        currentUser
    } = props;

    const [isReady, setIsReady] = useState(false);
    const { listingTypeHorse } = config;
    const { loc } = getCurrentUserLocation();
    const pub_activities = 'Pflegen';
    const boundaries = '58.02241898,19.88235533,42.28105558,-1.21139467';
    const [isMobile] = useIsMobile(1024);
    const title = intl.formatMessage({ id: 'SeoLandingPages.careShare.metaTitle' }) || '▷ Pflegebeteiligung finden - Tipps und Tricks für Pfleger:innen';

    const formatNumber = number => {
        return new Intl.NumberFormat('ch-CH', {
            minimumFractionDigits: 0,
        }).format(number);
    };

    const breadcrumbPaths = [{ label: 'Pflegebeteiligung finden' }];

    const [, , , , metaCH] = useListings({
        params: {
            per_page: 1,
            pub_type: listingTypeHorse,
            pub_userCountry: 'CH',
        },
    });

    const [, , , , metaDE] = useListings({
        params: {
            per_page: 1,
            pub_type: listingTypeHorse,
            pub_userCountry: 'DE',
        },
    });

    useEffect(() => {
        if (metaCH && metaDE) {
            setIsReady(true);
        }
    }, [metaCH, metaDE]);

    if (!isReady) {
        return null;
    }

    const countHorsesCH = metaCH ? metaCH.totalItems : 0;
    const countHorsesDE = metaDE ? metaDE.totalItems : 0;

    const accordionItems1 = [
        {
            title: 'Was ist eine Pflegebeteiligung?',
            content:
                'Eine Pflegebeteiligung für ein Pferd ist eine Art Tausch zwischen einer Pferdebesitzerin oder eines Pferdebesitzer und einem Pferdemenschen, die auf gegenseitigem Vertrauen und der Begeisterung für Pferde basiert. Bei einer Pflegebeteiligung sucht die Besitzerin oder der Besitzer des Pferdes oder Ponys für seinen oder ihren liebsten Vierbeiner nach einer liebevollen Person, die sich um es kümmert und es umsorgt. Die Pflegebeteiligung bekommt dafür die Möglichkeit, sich um ein Pferd zu kümmern, als wäre es das eigene, es zu pflegen und sich aktiv an seiner Betreuung zu beteiligen.\n' +
                '\n' +
                'Die genauen Aufgaben und Abläufe einer Pflegebeteiligung variieren je nach den Bedürfnissen und Wünschen des Pferdes, der Eigentümerin oder des Eigentümers und dir als Pflegebeteiligung. Teil einer Pflegebeteiligung sind jedoch so gut wie immer regelmässige Besuche im Stall, um das Pferd zu füttern, es zu putzen, zu striegeln und Zeit mit ihm zu verbringen. Wie oft Du als Pflegebeteiligung das Pferd besuchen darfst, wie lange Du Zeit mit ihm verbringst, was Du mit ihm machen darfst und welche Kosten auf dich zukommen, werden im Vorfeld definiert. Deswegen solltest Du es auch nicht überstürzen, eine Pflegebeteiligung zu finden und dir bei der Wahl genügend Zeit lassen.\n' +
                '\n' +
                'Hast Du eine passende Pflegebeteiligung gefunden, wirst Du wertvolle Erfahrungen im Umgang mit Pferden sammeln, ohne gleich die gesamte Verantwortung und Kosten für ein eigenes Pferd tragen zu müssen. Gleichzeitig profitiert die Pferdebesitzerin oder der Pferdebesitzer von deiner zuverlässigen Unterstützung und das Pferd ist nie lange alleine.',
        },
        {
            title: 'Welchen Zweck erfüllt eine Pflegebeteiligung?',
            content:
                'Eine Pflegebeteiligung ist eine Win-Win-Situation für alle: Die Besitzerin oder der Besitzer des Pferdes wird bei der Versorgung und Betreuung entlastet, das Pferd bekommt eine weitere fürsorgliche Vertrauensperson an die Seite gestellt und die Reitbeteiligung darf Zeit mit einem Pferd oder Pony verbringen und ihre Fähigkeiten im Umgang mit Pferden verbessern.\n' +
                '\n' +
                'Eine Pflegebeteiligung erfüllt also den Zweck, ein Pferd oder Pony bestmöglich zu versorgen. Denn ein eigenes Pferd zu haben bedeutet eine grosse Verantwortung und ein hohes zeitliches und finanzielles Invest. Damit das Pferd trotzdem immer gut umsorgt wird und Bewegung bekommt, gibt es die Pflegebeteiligungen. Denn auch im Leben einer Pferdebesitzerin oder eines Pferdebesitzers kann mal etwas dazwischen kommen: eine unvorhergesehene Dienstreise, eine längere Krankheit, ein nötiger Wohnortwechsel, und so weiter. Mit einer Pflegebeteiligung weiss die Besitzerin oder der Besitzer das Pferd trotzdem in guten Händen.\n' +
                '\n' +
                'Eine Pflegebeteiligung hat aber nicht nur für die Eigentümerin oder den Eigentümer des Pferdes Vorteile, sondern auch für dich. Du lernst als Pflegebeteiligung viel über den Umgang mit Pferden, sammelst Erfahrungen auf deinem Weg zu einem eventuellen eigenen Pferd und darfst viel Zeit mit deinem Lieblingstier verbringen. Zu den im Vorhinein vereinbarten Terminen bist Du alleinig für das Pferd oder Pony verantwortlich und kannst dadurch eine starke Bindung zu deinem Pflegepferd aufbauen. Schon nach kurzer Zeit wird es dir ans Herz wachsen, als wäre es dein eigenes.\n' +
                '\n' +
                'Natürlich profitiert auch das Pferd oder Pony von einer Pflegebeteiligung. Es wird sich darauf freuen, von dir versorgt zu werden und wird noch häufiger umsorgt, gestreichelt und gepflegt. Eine Extraportion Aufmerksamkeit sozusagen. ',
        },
        {
            title: 'Pflegebeteiligung finden - wie geht das?',
            content:
                'Es gibt viele verschiedene Wege, eine Pflegebeteiligung zu finden. Klassischerweise hat man ein Pflegepferd über Freunde, Bekannte oder Ställe in der Nähe gefunden. Das kostet jedoch viel Zeit, ist mühevoll und oft Glückssache. Manchmal kennen Freunde eine:n Pferdebesitzer:in, die oder der nach einer Pflegebeteiligung sucht oder es gibt einen entsprechenden Aushang am Schwarzen Brett eines Reitvereins.\n' +
                '\n' +
                'Wir haben jedoch eine bessere Alternative für dich: Finde mit HorseDeal deine Pflegebeteiligung ganz einfach und schnell online. Du gibst einfach deinen Standort sowie den gewünschten Umkreis an, in dem Du nach einem Pflegepferd suchst. Anschliessend präsentieren wir dir eine Auswahl an passenden Möglichkeiten. Du siehst direkt wie viel die Pflegebeteiligung kostet, wo das Pferd steht und welche Aufgaben dich erwarten. Hast Du deine Favoriten gefunden, kannst Du direkt über unsere Plattform Kontakt zum Eigentümer oder zur Eigentümerin aufnehmen und ein Kennenlerngespräch vereinbaren. In diesem Gespräch hast Du die Gelegenheit, alle offenen Fragen zu stellen und euch gegenseitig kennenzulernen. Je mehr Themen ihr bereits im Kennenlerngespräch ansprecht und je mehr Fragen ihr klärt, desto besser könnt ihr abschätzen, ob ihr gut zusammenpasst. Die Chemie zwischen dir, der Besitzerin oder des Besitzers und dem Pferd sollte unbedingt stimmen, um unnötigen Stress zu vermeiden.\n' +
                '\n' +
                'Sei beim Kennenlernen immer ehrlich und höre auf dein Bauchgefühl. Auch wenn Du dich gut präsentieren möchtest ist es wichtig, deine Erfahrung im Umgang mit Pferden so detailliert wie möglich zu beschreiben und zu sagen, welche Tätigkeiten Du gerne ausüben möchtest, wie oft Du den Stall besuchen kannst und welche zusätzlichen Aufgaben Du übernehmen würdest. Dadurch kann die Pferdebesitzerin oder der Pferdebesitzer genauer einschätzen, ob Du gut zum Pferd passt und ob er oder sie dir seinen besten Freund anvertrauen möchte.',
        },
        {
            title: 'Gibt es bestimmte Voraussetzungen für eine Pflegebeteiligung?',
            content:
                'Als Pflegebeteiligung brauchst Du keine besonderen Voraussetzungen. Reiterfahrung oder viele genommene Reitstunden sind also nicht unbedingt nötig. Natürlich solltest Du für eine Pflegebeteiligung besonders pferdelieb sein, viel Zeit im Stall verbringen wollen und bestimmte Charaktereigenschaften haben, die dich zu einer zuverlässigen Bezugsperson machen. Dazu gehören zum Beispiel Pünktlichkeit, Zuverlässigkeit, Ehrlichkeit, gute Kommunikationsfähigkeiten und ein grosses Verantwortungsbewusstsein.\n' +
                '\n' +
                'Eine Pflegebeteiligung bietet sich für Menschen jedes Alters und jedes Reit-Levels an. Etwas Erfahrung im Umgang mit Pferden wäre optimal, da dir dann die Besitzerin oder der Besitzer des Pferdes nicht erst viel zeigen müssen.\n' +
                '\n' +
                'Besonders beliebt sind Pflegebeteiligungen für Kinder und Jugendliche, die sich sehnlichst ein eigenes Pferd wünschen und deswegen den Umgang mit Pferden suchen. Voraussetzung ist natürlich das Einverständnis der Erziehungsberechtigten oder Eltern. Ohne eine (schriftliche) Einverständniserklärung können Minderjährige keine Pflegebeteiligung abschliessen. Bei kleineren Kindern kann es ausserdem erforderlich sein, dass ein Elternteil mit in den Stall kommt, um beim Putzen, Umgang mit dem Pferd und beim Handling von Ausrüstungsgegeständen zu helfen. Teenager können sich selbstständig um eine Pflegepferd kümmern und damit viel Verantwortung übernehmen.\n' +
                '\n' +
                'Ab 18 Jahren brauchst Du das natürlich nicht mehr und kannst auf eigene Faust eine Pflegebeteiligung finden. Schildere beim Kennenlerngespräch unbedingt deine bisherige Erfahrung mit Pferden und sage aufrichtig, wenn Du zunächst noch eine Einführung in die Pferdepflege brauchst. Viele Pferdebesitzer:innen sind bereit, dir alles zu zeigen und dir beim Umgang mit dem Pferd zu helfen, wenn sie merken, dass Du es ernst meinst und lernen willst.\n' +
                '\n' +
                'Um rechtlich abgesichert zu sein, empfehlen wir dir ausserdem einen Vertrag und einen ausreichenden Versicherungsschutz. Suchst Du eine Pflegebeteiligung über HorseDeal, stehen wir dir dabei zur Seite.',
        },
        {
            title: 'Welche Aufgaben und Verantwortlichkeiten umfasst eine Pflegebeteiligung?',
            content:
                'Du möchtest wissen, was als Pflegebeteiligung von dir erwartet wird? Eine Pflegebeteiligung umfasst in der Regel das Putzen, Striegeln, Streicheln des Pferdes oder Ponys und meist auch das Füttern und Stallausmisten. Die genauen Aufgaben unterscheiden sich je nach Art der Pflegebeteiligung und sind häufig Ausmachungssache. Wichtig ist es jedoch immer, die Verantwortlichkeiten im Vorhinein genau zu klären.\n' +
                'Die Aufgaben als Pflegebeteiligung sind wichtig und kosten Zeit, sind aber für so gut wie alle pferdelieben Menschen machbar. Du solltest etwas Erfahrung im Umgang mit Pferden mitbringen und vor allem zuverlässig sein, brauchst aber keine Reiterfahrung.\n' +
                '\n' +
                'Wenn Du ein Pflegepferd gefunden hast, bist Du im Normalfall mindestens einmal bis mehrmals pro Woche allein für die gesamte Pflege des Pferdes verantwortlich. Das heisst, dass Du seine Box ausmistest und das Pferd putzt und fütterst. Eine Pflegebeteiligung zu sein bedeutet daher viel Verantwortung und sollte nicht auf die leichte Schulter genommen werden.\n' +
                '\n' +
                'Trotz aller Verantwortung umfasst eine Pflegebeteiligung auch die Beschäftigung mit dem Pferd und macht vor allem viel Spass. Du hast genügend Zeit, dich um einen Vierbeiner zu kümmern, gemeinsam die frische Luft zu geniessen und natürlich viel zu kuscheln.',
        },
        {
            tableOfContentTitle: 'Wie Kommuniziere ich mit dem oder der Pferdebesitzer:in?',
            title:
                'Wie gestaltet sich die Kommunikation und Zusammenarbeit mit der Besitzerin oder dem Besitzer des Pferdes?',
            content:
                'Wie genau die Kommunikation zwischen dir und der Besitzerin oder dem Besitzer des Pferdes abläuft, sollte vor Beginn der Pflegebeteiligung besprochen und festgelegt werden. Dabei ist es egal, auf welchem Weg ihr kommuniziert, solange ihr euch zeitnah erreicht.\n' +
                '\n' +
                'Einige Pferdebsitzer:innen bevorzugen den schriftlichen Austausch per Mail oder WhatsApp, während die meisten hin und wieder gerne telefonieren. Manchmal entsteht auch eine enge Freundschaft, sodass sich Besitzer:in und Pflegebeteiligung mehrmals pro Woche treffen und gemeinsam Zeit verbringen. Du siehst also: Der Zeitpunkt und die Art der Kommunikation ist ganz individuell. Grundsätzlich solltest Du bei unvorhergesehenen Ereignissen, bei Fragen oder bei auftretenden Problemen natürlich sofort Kontakt mit der Pferdebesitzerin oder dem Pferdebesitzer aufnehmen. Deswegen solltest Du von Beginn an mehrere Kontaktmöglichkeiten zur Verfügung haben. Tauscht dafür beim Kennenlerngespräch also am besten gleich eure Telefonnummern und E-Mail-Adressen aus. Ganz wichtig ist auch eine frühzeitige Absage, sollte bei dir ein Notfall sein und Du einen Termin mal nicht wahrnehmen können.\n' +
                '\n' +
                'Eine erfolgreiche Pflegebeteiligung braucht unbedingt eine offene, respektvolle und ehrliche Kommunikation von beiden Seiten. Zwischen dir und der Pferdebesitzerin oder dem Pferdebesitzer sollte immer eine absolute Transparenz und Vertrauen herrschen. Bei Meinungsverschiedenheiten solltet ihr auch mal euer Ego in den Hintergrund schieben, da es letztendlich immer um das Wohl des Pferdes geht und ihr eigentlich beide nur das Beste für den Vierbeiner wollt.\n' +
                '\n' +
                'Um die Kommunikation im Voraus und während der Pflegebeteiligung so leicht wie möglich zu machen, kannst Du bei HorseDeal direkt mit der Eigentümerin oder dem Eigentümer schreiben. So habt ihr alle Gespräche, Termine und Absprachen gesammelt an einem Ort und verliert nie den Überblick.',
        },
    ];

    const accordionItems2 = [
        {
            title: 'Was muss man für eine Pflegebeteiligung zahlen?',
            content:
                'Die genauen Kosten für eine Pflegebeteiligung anzugeben ist sehr schwer, da sie sich je nach Pferd, Standort, Häufigkeit der Stallbesuche, den übernommenen Aufgaben usw. unterscheiden. Bei HorseDeal siehst Du in den Inseraten jedoch direkt, wie viel die Pferdebesitzer:innen für eine Pflegebeteiligung als monetäre Beteiligung verlangen. Mit deinem Beitrag unterstützt Du die Besitzerin oder den Besitzer bei den Fixkosten für die Pferdehaltung. Dazu gehören zum Beispiel die Stallmiete, Kosten für Versicherungen und Kosten für das Futter. Im Gegenzug darfst Du Zeit mit dem Pferd oder Pony verbringen und hast sozusagen ein eigenes Pferd auf Zeit.\n' +
                '\n' +
                'Meistens zahlt man monatlich für eine Pflegebeteiligung und die Gebühr ist geringer als für eine Reitbeteiligung. Je mehr der ungeliebten Aufgaben rund um die Pflege und Stallarbeit Du übernimmst, desto weniger Geld musst Du meist auch zahlen. Manche Pflegebeteiligungen sind sogar kostenlos, das ist jedoch sehr selten. Im Schnitt solltest Du mit 40 bis 200 Franken oder Euro für eine Pflegebeteiligung rechnen.',
        },
        {
            tableOfContentTitle: 'Welche Versicherungen oder Verträge brauche ich?',
            title:
                'Welche Vereinbarungen oder Verträge sollten bei einer Pflegebeteiligung abgeschlossen werden?',
            content:
                'Um bei deiner Pflegebeteiligung abgesichert zu sein sind vor allem zwei Punkte wichtig: Ein guter Vertrag und passende Versicherungen. Denn leider können selbst bei einer Pflegebeteiligung ab und zu kleine Zwischenfälle oder Unfälle auftreten. Obwohl solche Missgeschicke glücklicherweise selten sind, empfehlen wir dir dennoch, gut vorbereitet zu sein. Daher ist es vor Antritt einer Pflegebeteiligung sinnvoll, deine bestehenden Versicherungen zu überprüfen und gegebenenfalls anzupassen. Spreche dich dafür am besten mit deiner Versicherungsvertreterin oder deinem Versicherungsvertreter ab und lass dich gründlich beraten.\n' +
                '\n' +
                'Als Basis können wir dir eine grundlegende Haftpflichtversicherung ans Herz legen. Diese kommt zum Beispiel für Schäden am Stall auf, die Du beim Umgang mit dem Pferd verursachen könntest. Für deine eigene Sicherheit solltest Du ausserdem prüfen, ob deine Unfall- und/oder Privatkrankenversicherung auch Unfälle im Zusammenhang mit Tieren abdeckt. Falls nicht, kannst Du hier noch nachbessern.\n' +
                'In Sachen Versicherungen für das Pferd musst Du als Pflegebeteiligung eigentlich nichts beachten. In den meisten Fällen kümmert sich die Pferdebesitzerin oder der Pferdebesitzer um die nötigen Versicherungen für das Pferd, wie zum Beispiel eine Pferde-OP-Versicherung, und übernimmt die Kosten dafür. Versicherungen und Absprachen dazu könnt ihr auf jeden Fall auch in eurem Vertrag festhalten, um hier Klarheit zu haben.\n' +
                '\n' +
                'Ein solcher Vertrag muss nicht kompliziert sein oder formell klingen, sondern sollte einfach die Grundlagen und Regeln schriftlich festhalten, damit beide Parteien wissen, was sie voneinander erwarten können. Der Vertrag sollte vor Beginn der Pflegebeteiligung abgeschlossen werden. Habt ihr das vergessen, könnt ihr es natürlich jederzeit nachholen.\n' +
                '\n' +
                'Der Vertrag rund um die Pflegebeteiligung sollte neben den Versicherungen wichtige Informationen wie eure Namen, Adressen und Kontaktdaten, die Zustimmung der Erziehungsberechtigten oder Eltern (falls erforderlich, insbesondere bei Minderjährigen), die Aufgaben und Verantwortlichkeiten im Rahmen der Pflegebeteiligung, die Vorgehensweise bei Unfällen oder Verletzungen, anfallende monatliche Kosten, die Kündigungsfrist, die eventuelle Erlaubnis zur Mitnahme von anderen Personen zum Stallbesuch und alle anderen wichtigen Details enthalten. Je detaillierter der Vertrag ist, desto besser.',
        },
        {
            tableOfContentTitle: 'Kann ich als Pflegebeteiligung auch reiten oder spazieren?',
            title: 'Kann ich als Pflegebeteiligung das Pferd auch reiten oder spazieren führen?',
            content:
                'Als Pflegebeteiligung kannst Du das Pferd oder Pony normalerweise nicht reiten. Willst Du auch reiten, solltest Du dich nach einer <a href="/landing/reitbeteiligung-finden">Reitbeteiligung</a> umschauen. Möchtest Du mit dem Pferd spazieren gehen, ist eine <a href="/landing/spazierbeteiligung-finden">Spazierbeteiligung</a> das Richtige für dich. Oft werden die verschiedenen Bezeichnungen für Reitbeteiligungen jedoch nicht ganz trennscharf genutzt. So kann es also sein, dass Du auch bei einer Pflegebeteiligung reiten, Bodenarbeit machen oder spazieren darfst. Deswegen ist es wichtig, deine Vorstellungen unbedingt im Vorhinein zum Ausdruck zu bringen und mit der Besitzerin oder dem Besitzer genau zu klären, was Du mit dem Pferd machen darfst.\n' +
                '\n' +
                'Auf jeden Fall solltest Du dich immer an eure Abmachung halten und nicht heimlich mit dem Pferd spazieren gehen oder es reiten, obwohl es nicht in eurer Absprache enthalten ist. Häufig wird nämlich für alte, verletzte oder aus anderen Gründen nicht fürs Reiten geeignete Pferde nach einer Pflegebeteiligung gesucht. Missachtest Du eure Abmachung, schadest Du nicht nur dem gegenseitigen Vertrauen, sondern auch dem Pferd.\n' +
                '\n' +
                'Suchst Du nach einer <a href="/landing/reitbeteiligung-finden">Reitbeteiligung<a/> oder einer <a href="/landing/spazierbeteiligung-finden">Spazierbeteiligung</a>, schaue dir also unbedingt unsere passenden Seiten dazu an. \n' +
                'Trotzdem ist eine Pflegebeteiligung nicht langweilig und enthält vielfältige Aufgaben. Zusätzlich ist sie ein idealer Einstieg, wenn Du später eine Reitbeteiligung werden möchtest.',
        },
        {
            tableOfContentTitle: 'Was tun bevor ich eine Pflegebeteiligung eingehe?',
            title:
                'Wie kann ich sicherstellen, dass die Chemie zwischen mir und dem Pferd bzw. Besitzer:in stimmt, bevor ich mich für eine Pflegebeteiligung entscheide?',
            content:
                'Bei einer Pflegebeteiligung ist es wichtig, dass nicht nur die Beziehung zwischen dir und dem Pferd, sondern auch die zwischen dir und der Eigentümerin oder dem Eigentümer stimmt. Wie kannst Du beim Pflegebeteiligung finden also sicherstellen, dass die Chemie zwischen euch stimmt?\n' +
                '\n' +
                'Stelle viele Fragen, höre aufmerksam zu und achte auf dein Bauchgefühl. Hast Du den Eindruck, dass irgendetwas nicht stimmt, hat das meist einen Grund. In dem Fall solltest Du entweder noch genauer nachfragen oder dich nach einer anderen Pflegebeteiligung umschauen. Manchmal stimmt auch einfach die Chemie nicht, was auch völlig okay ist. Hauptsache, ihr erkennt das vor Beginn der Pflegebeteiligung.\n' +
                'Dafür solltest Du beim ersten Kennenlernen offene Fragen stellen und die Antworten mit deinen Vorstellungen von deiner Wunsch-Pflegebeteiligung abgleichen. Das Gleiche gilt natürlich für die Besitzerin oder den Besitzer des Pferdes. Ihr seid auf einer Wellenlänge was die Vorstellung von Aufgaben und Verantwortlichkeiten betrifft? Super! Auch ein respektvoller und offener Austausch ist ein gutes Zeichen für eine funktionierende Zusammenarbeit.\n' +
                '\n' +
                'Hier haben wir einige Fragen, die Du bereits vor oder im Gespräch stellen könntest, für dich zusammengestellt:\n' +
                '<ul>' +
                '<li>Wie oft, wie lange und wann sollte ich mich um das Pferd kümmern?</li>' +
                '<li>Welche konkreten Aufgaben sind mit der Pflegebeteiligung verbunden?</li>' +
                '<li>Welche Verhaltensregeln gelten im Umgang mit dem Pferd und im Stall?</li>' +
                '<li>Gibt es besondere Bedürfnisse des Pferdes, die ich beachten sollte?</li>' +
                '<li>Wie sind die finanziellen Regelungen bezüglich eventueller Tierarztkosten oder anderer Ausgaben?</li>' +
                '<li>Gibt es vorhandene Versicherungen?</li>' +
                '<li>Sind besondere Vorkenntnisse oder Fähigkeiten im Umgang mit Pferden erforderlich?</li>' +
                '<li>Gibt es spezielle Regeln für das Reiten des Pferdes, die Bodenarbeit oder das Spazieren, falls dies Teil der Pflegebeteiligung ist?</li>' +
                '<li>Wie ist die Vorgehensweise bei Terminabsagen oder unerwarteten Ereignissen?</li>' +
                '<li>Welche Kontaktdaten sollte ich im Notfall verwenden?</li>' +
                '<li>Gibt es besondere Verhaltensweisen, die ich im Umgang mit dem Pferd beachten sollte?</li>' +
                '<li>Darf ich andere Personen mitbringe?</li>' +
                '</ul>',
        },
        {
            tableOfContentTitle: 'Welche Alternativen zur Pflegebeteiligung gibt es?',
            title:
                'Welche anderen Möglichkeiten gibt es, um mit Pferden Zeit zu verbringen, wenn eine Pflegebeteiligung nicht möglich ist?',
            content:
                'Du hast dein Traumpferd gefunden, doch eine Pflegebeteiligung ist nicht möglich? Dann gibt es noch andere Möglichkeiten, um Zeit mit Pferden zu verbringen. Andere Arten der Beteiligungen sind zum Beispiel die <a href="/landing/spazierbeteiligung-finden">Spazierbeteiligung</a> oder die <a href="/landing/reitbeteiligung-finden">Reitbeteiligung</a>. Und sonst heisst es, kreativ zu werden. Denn Pferde brauchen an vielen Orten eine liebevolle Pflege und Aufmerksamkeit.\n' +
                '\n' +
                'Dein erster Anlaufpunkt kann ein örtlicher Stall oder Bauernhof in deiner Nähe sein. Frage einfach mal nach, ob es Freiwilligenprojekte oder Aushilfsjobs im Stall gibt. So kannst Du Pferdeluft schnuppern, tust etwas Gutes und verdienst eventuell sogar noch Geld dabei. Auch im Tierheim oder auf Tierwohl-Farmen lohnt es sich, nachzufragen.',
        },
    ];

    return (
        <StaticPage
            title={title}
            schema={{
                '@context': 'https://schema.org',
                '@type': 'PflegebeteiligungFindenPage',
                description: intl.formatMessage({ id: 'SeoLandingPages.careShare.metaDescription' }),
                name: 'Pflegebeteiligung finden page'
            }}
        >
            <LayoutSingleColumn>
                <LayoutWrapperTopbar>
                    <TopbarContainer />
                </LayoutWrapperTopbar>

                <LayoutWrapperMain
                    className={css.staticPageWrapper}
                    blobBackground
                    blobProps={{
                        xDistance: 20,
                        baseWidth: 760,
                    }}
                >
                    <Breadcrumb
                        paths={breadcrumbPaths}
                        urlPrefix={"/landing"}
                    />

                    <h1 className={css.pageTitle}>
                        <FormattedMessage
                            id="SeoLandingPages.careShare.Title"
                            defaultMessage="<highlight>Pflegebeteiligung finden</highlight> - Tipps {break}und Ticks für Pfleger:innen"
                            values={{
                                break: <br className={css.pageTitleBreak} />,
                                highlight: (chunks) => <span className={css.pageTitleColor}>{chunks}</span>
                            }}
                        />
                    </h1>

                    <div className={css.coverWrapper}>
                        <div className={css.findPlacesNearby}>
                            <h2 className={css.coverTitle}>Pflegebeteiligungen finden in der
                                Nähe</h2>
                            <p className={css.coverSubTitle}>Finde Pferde auf der Suche nach einer
                                Pflegebeteiligung.</p>
                            <div className={css.dumbInputRoot}>
                                <input
                                    type="text"
                                    className={css.dumbInput}
                                    placeholder="Suche in deiner Nähe"
                                    onFocus={() =>
                                        redirectToURLWithModalState(
                                            { location, history },
                                            isMobile ? 'mobilesearch' : 'desktopsearch',
                                        )
                                    }
                                />
                                <div className={css.dumbFieldIconHolder}>
                                    <MagnifyIcon />
                                </div>
                            </div>
                            <div className={css.reviewsIframe}>
                                <iframe name="text-banner-widget_frame"
                                        id="text-banner-widget_frame"
                                        frameBorder="0" width="100%" scrolling="no" height="66"
                                        title="Reviews Widget"
                                        src="https://widget.reviews.io/rich-snippet-reviews-widgets/widget?version=13b&amp;store=www.horsedeal.com&amp;starsClr=%23faba05&amp;textClr=%231a2b49&amp;logoClr=white&amp;widgetName=text-banner&amp;translateOurCustomersSay=%20&amp;translateOutOf5BasedOn=bei&amp;translateReviews=Bewertungen&amp;translateExcellent=Hervorragend&amp;css=.TextBanner%20.ReviewsLogo%20%7B%20display%3A%20none%20%7D&amp;elementId=text-banner-widget&amp"></iframe>
                            </div>
                        </div>

                        <div id="text-banner-widget"
                             style={{ maxWidth: 0, maxHeight: 0, transform: 'scale(0)' }} />
                        <div id="text-banner-widget-mobile"
                             style={{ maxWidth: 0, maxHeight: 0, transform: 'scale(0)' }} />
                        <div className={css.coverImageWrapper}>
                            <img className={css.coverImage}
                                 src="https://horsedeal.imgix.net/static/landingPage/banner/pflegebeteiligung-finden.webp"
                                 alt="Pflegebeteiligungen finden" />
                        </div>
                    </div>

                    <div className={css.faq}>
                        <h2 className={css.contentHeading}>Inhaltsverzeichnis</h2>
                        {generateAccordionTableOfContents([accordionItems1, accordionItems2])}
                    </div>

                    <AccordionItems items={accordionItems1} expandFirstElement={true} />

                    <HorseDealCreateAccountTeaser
                        title={intl.formatMessage({ id: 'SeoLandingPages.careShare.AccountTeaserTitle' })}
                        participationName={intl.formatMessage({ id: 'SeoLandingPages.careShare.AccountTeaserParticipationName' })}
                        currentUser={currentUser}
                        bounds={boundaries}
                        origin={loc}
                        listingType={listingTypeHorse}
                        pub_activities={pub_activities}
                    />

                    <ReviewsWidget className={css.reviewsioCarouselWidget} name={title} />

                    <AccordionItems items={accordionItems2} />

                    <div className={css.ridingSharesEuropeWrapper}>
                        <h2 className={css.ridingSharesEuropeTitle}>Pflegebeteiligungen in <span className={css.pageTitleColor}>Europa</span>
                        </h2>
                        <p>Finde Pflegebeteiligungen in allen Ländern Europas.</p>
                        <div className={css.ridingSharesEuropeInnerWrapper}>
                            <div className={css.ridingSharesCountry}>
                                <a href="/landing/pflegebeteiligung-finden/deutschland" className={css.ridingSharesTitle}>Pflegebeteiligungen in
                                    Deutschland
                                </a>
                                <div
                                    className={css.ridingSharesCount}>{formatNumber(countHorsesDE)} {countHorsesDE === 1 ? 'Pferd' : 'Pferde'}</div>
                            </div>
                            <div className={css.ridingSharesCountry}>
                                <a href="/landing/pflegebeteiligung-finden/schweiz"
                                   className={css.ridingSharesTitle}>Pflegebeteiligungen in der
                                    Schweiz
                                </a>
                                <div
                                    className={css.ridingSharesCount}>{formatNumber(countHorsesCH)} {countHorsesCH === 1 ? 'Pferd' : 'Pferde'}</div>
                            </div>
                        </div>
                    </div>

                    <HorseDealAppTeaser />

                </LayoutWrapperMain>

                <LayoutWrapperFooter>
                <Footer />
                </LayoutWrapperFooter>
            </LayoutSingleColumn>
        </StaticPage>
    );
};

PflegebeteiligungFindenPageComponent.defaultProps = {
    title: null,
    currentUser: null,
};

PflegebeteiligungFindenPageComponent.propTypes = {
    title: string,
    currentUser: propTypes.currentUser,
    intl: intlShape.isRequired
};

const mapStateToProps = state => ({
    currentUser: state.user.currentUser,
});

const PflegebeteiligungFindenPage = compose(
    connect(mapStateToProps),
    withRouter,
    injectIntl
)(PflegebeteiligungFindenPageComponent);

export default PflegebeteiligungFindenPage;
