import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { FormattedMessage, injectIntl, intlShape } from '../../../../util/reactIntl';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { string } from 'prop-types';
import { propTypes } from '../../../../util/types';
import { StaticPage, TopbarContainer } from '../../../index';
import {
    LayoutSingleColumn,
    LayoutWrapperTopbar,
    LayoutWrapperMain,
    LayoutWrapperFooter,
    Footer,
    Breadcrumb,
    AccordionItems,
    generateAccordionTableOfContents,
    HorseDealAppTeaser,
    HorseDealCreateAccountTeaser,
} from '../../../../components';

import css from '../LandingPage.css';
import { redirectToURLWithModalState } from '../../../../components/Topbar/Topbar.helpers';
import MagnifyIcon from '../../../ListingPage/Icons/MagnifyIcon';
import { useIsMobile } from '../../../../hooks/useIsMobile';
import config from '../../../../config';
import { useListings } from '../../../../hooks/useListings';
import { ReviewsWidget } from '../../../AuthenticationPage/ReviewsWidget';
import { getCurrentUserLocation } from '../../../../util/localStorage';

const ReitbeteiligungFindenPageComponent = props => {
    const {
        intl,
        location,
        history,
        currentUser
    } = props;

    const [isReady, setIsReady] = useState(false);
    const { listingTypeHorse } = config;
    const { loc } = getCurrentUserLocation();
    const pub_activities = 'Spazieren,Training,Turniere';
    const boundaries = '58.02241898,19.88235533,42.28105558,-1.21139467';
    const [isMobile] = useIsMobile(1024);
    const title = intl.formatMessage({ id: 'SeoLandingPages.ridingShare.metaTitle' }) || '▷ Reitbeteiligung finden - Tipps und Tricks für Reiter:innen';

    const formatNumber = number => {
        return new Intl.NumberFormat('ch-CH', {
            minimumFractionDigits: 0,
        }).format(number);
    };

    const breadcrumbPaths = [{ label: 'Reitbeteiligung finden' }];

    const [, , , , metaCH] = useListings({
        params: {
            per_page: 1,
            pub_type: listingTypeHorse,
            pub_userCountry: 'CH',
        },
    });

    const [, , , , metaDE] = useListings({
        params: {
            per_page: 1,
            pub_type: listingTypeHorse,
            pub_userCountry: 'DE',
        },
    });

    useEffect(() => {
        if (metaCH && metaDE) {
            setIsReady(true);
        }
    }, [metaCH, metaDE]);

    if (!isReady) {
        return null;
    }

    const countHorsesCH = metaCH ? metaCH.totalItems : 0;
    const countHorsesDE = metaDE ? metaDE.totalItems : 0;

    const accordionItems1 = [
        {
            title: 'Was ist eine Reitbeteiligung?',
            content:
                'Bei einer Reitbeteiligung teilen sich die Besitzerin oder der Besitzer und ein bis drei andere Reiter:innen die Aufgaben und Fürsorge rund um ein Pferd. Die Pferdehalterin oder der Pferdehalter stellt das eigene Pferd für eine gewissen Anzahl an Reitstunden oder Tagen einer Reiterin oder einem Reiter zur Verfügung. Im Gegenzug kümmert diese:r sich an den zugeteilten Tagen um das Pferd und zahlt meist einen monatlichen Betrag, um die Besitzerin oder den Besitzer bei den anfallenden Kosten für die Pferdehaltung zu unterstützen. Die genaue Aufgabenverteilung und die Kosten sind meist vertraglich geregelt.\n' +
                '\n' +
                'Eine Reitbeteiligung zu finden hat viele Vorteile: Der Besitzer oder die Besitzerin muss die hohen Kosten und die Verantwortung nicht alleine tragen, das Pferd bekommt genügend Bewegung und extra viel Liebe und die Reitbeteiligung kann „eigenes-Pferd-Luft“ schnuppern.\n' +
                '\n' +
                'Eine Reitbeteiligung ist ein wichtiger Schritt auf dem Weg zum ersten eigenen Pferd, da Du so die entsprechende Erfahrung sammelst und den tatsächlichen Aufwand, die hohen Kosten und die grosse Verantwortung, die mit einem eigenen Pferd daherkommen, besser einschätzen kannst. Viele fortgeschrittene Reiter:innen bleiben sogar ein Leben lang bei Reitbeteiligungen, da das Modell viele Vorteile mit sich bringt. Möchtest Du mehr über Reitbeteiligungen erfahren oder eine Reitbeteiligung finden, bist Du hier bei HorseDeal genau richtig. Wir sind die grösste Plattform für Reitbeteiligungen in Europa und versorgen dich mit allen Tipps und Hintergrundinformationen.',
        },
        {
            tableOfContentTitle: 'Welche Arten der Reitbeteiligung gibt es?',
            title: 'Gibt es verschiedene Arten der Reitbeteiligung?',
            content:
                'Reitbeteiligungen sind so individuell wie Du als Reiter:in. Was genau deine Reitbeteiligung beinhaltet, welche Aufgaben Du übernimmst und welche Rechte und Pflichten Du hast, wird meist vertraglich und speziell für die jeweilige Pferd-, Besitzer- und Reiter-Konstellation geregelt. Deswegen sind genaue Absprachen vor dem Beginn einer Reitbeteiligung unerlässlich. Die wichtigsten Unterschiede bei einer Reitbeteiligung sind die Pflegebeteiligung, die Spazierbeteiligung, die Reitbeteiligung mit Turnierteilnahme, die Reitbeteiligung mit Kaufoption, die Reitbeteiligung im Reitunterricht und die Reitbeteiligung auf Schulpferd.\n' +
                '\n' +
                'Bei einer Pflegebeteiligung darfst Du das Pferd meist nicht reiten, sondern kümmerst dich überwiegend um die Pflege des Pferdes und leistest ihm Gesellschaft. Bei einer Spazierbeteiligung gehst Du zusätzlich noch mit ihm spazieren. Diese zwei Arten von Reitbeteiligungen sind meist günstiger und vor allem für Reitanfänger, kleinere Kinder oder pferdeliebe Menschen geeignet, die mit Reiten nichts am Hut haben. Bei klassischen Reitbeteiligungen reitest Du das Pferd meist nur im Training, ausser Du hast eine Reitbeteiligung mit Turnierteilnahme. Dann darfst Du mit dem Pferd auch auf Turnieren antreten. Eine Reitbeteiligung mit Kaufoption wird meist abgeschlossen, um die Kompatibilität zwischen Pferd und Reiter:in zu prüfen, während eine Reitbeteiligung auf Schulpferden oder im Reitunterricht meist über den jeweiligen Stall organisiert wird.\n' +
                '\n' +
                'Bevor Du dich für eine Reitbeteiligung entscheidest, solltest Du dir also bewusst machen, nach welcher Art von Reitbeteiligung Du genau suchst. Nur so kannst du die passende Reitbeteiligung finden. Hier auf HorseDeal findest Du alle Optionen, sodass deine Suche nach der optimalen Reitbeteiligung nicht lange dauert! Eine <a href="https://www.horsedeal.com/blog/reitbeteiligung-ja-oder-nein">Entscheidungshilfe für oder gegen eine Reitbeteiligung</a> geben wir dir auch in diesem Blogbeitrag.',
        },
        {
            tableOfContentTitle:
                'Was ist der Unterschied zwischen Reitbeteiligung und Pflegebeteiligung?',
            title:
                'Was ist der Unterschied zwischen einer Reitbeteiligung und einer Pflegebeteiligung?',
            content:
                'Pflegepferde (oder eine Pflegebeteiligung) brauchen keine bestimmten Voraussetzungen. Deswegen sind häufig alte Pferde oder Ponys auf der Suche nach einer Pflegebeteiligung. Aber auch Reitpferde werden als Pflegepferde vergeben, vor allem an grössere Kinder, damit diese lernen, Verantwortung zu übernehmen und sich um ein Lebewesen zu kümmern. Eine Pflegebeteiligung ist ideal für Familien, für Kinder jedes Alters, für Reitanfänger oder für pferdeliebe Menschen, die nicht reiten. Pflegebeteiligungen sind oft günstiger als Reitbeteiligungen. Der Zeitaufwand und die Verantwortung für das Pflegepferd sollte jedoch trotzdem nicht unterschätzt werden. Eine Pflegebeteiligung erfordert das gleiche Mass an Zuverlässigkeit, Arbeit und Fürsorge.\n' +
                '\n' +
                'Reitbeteiligungen hingegen werden meist nur an Erwachsene oder heranwachsende Reiter:innen vergeben, die schon etwas Erfahrung mitbringen. Bei einer Reitbeteiligung kümmerst Du dich nicht nur um das Pferd, sondern sollst es auch reiten. Ob Du es im Reitunterricht, im eigenen Training oder sogar auf Turnieren reiten darfst, ist Sache der Absprache. Deswegen gibt es viele Arten von Reitbeteiligungen. Die Kosten und der Zeitaufwand unterscheiden sich dementsprechend auch deutlich von Reitbeteiligung zu Reitbeteiligung. Mache Pferdebesitzer:innen sind selbst auch aktive Reiter:innen und kümmern sich an gewissen Tagen selbst um das Pferd, während andere zum Beispiel im Ausland leben und Du das Pferd mehr oder weniger ganz für dich hast oder es dir nur mit anderen Reitbeteiligungen teilst.',
        },
        {
            title: 'Reitbeteiligung finden - wie geht das?',
            content:
                'Eine Reitbeteiligung zu finden geht über verschiedene Wege. In Ställen hängen Gesuche nach Reitbeteiligungen oft am schwarzen Brett oder Du erfährst von offenen Reitbeteiligungen über deine Stallkollegen oder Reitlehrer. Das ist jedoch immer etwas Glückssache und es kann lange dauern, auf diesem Weg eine Reitbeteiligung zu finden. Auch über die Kleinanzeigen auf diversen Plattformen im Internet werden Reitbeteiligungen ausgeschrieben. Manchmal gibt es auch Gruppen auf Social Media, die sich mit Reitbeteiligungen in deiner Nähe oder Pflegepferden beschäftigen.\n' +
                '\n' +
                'Am einfacsten ist die Suche nach einer Reitbeteiligung über HorseDeal. Wir sind die grösste Plattform für Reitbeteiligungen in Europa. Mit mehreren tausend Mitgliedern ist die Auswahl bei uns sehr gross und es ist leichter, eine passende Reitbeteiligung bei dir in der Nähe zu finden. Über die Suche gibst Du einfach deinen Standort ein und bekommst passende Ergebnisse angezeigt. Dann brauchst Du nur noch nach deinen Kriterien einzugrenzen und kannst schon deine Favoriten kennenlernen.'
        },
        {
            title: 'Wie alt muss man sein, um eine Reitbeteiligung zu haben?',
            content:
                'Für eine Reitbeteiligung solltest Du mindestens zwölf Jahre alt sein, bereits etwas Erfahrung mitbringen und das Einverständnis deiner Erziehungsberechtigten haben. Kleinere Kinder sollten nur eine Reitbeteiligung haben, wenn sie bereits reiten können und die Eltern mit anpacken. Schliesslich kommt eine Reitbeteiligung mit anspruchsvollen Aufgaben daher und die Verantwortung sollte ernst genommen werden. Ab 18 Jahren darfst Du Reitbeteiligungen natürlich in deinem eigenen Namen abschliessen. Hier gibt es keine Einschränkungen mehr und alle Rechte und Pflichten sind Ausmachungssache zwischen dir und dem Pferdebesitzer.',
        },
        {
            tableOfContentTitle: 'Wie kann ich eine Reitbeteiligung finden?',
            title: 'Bin ich bereit für eine Reitbeteiligung?',
            content:
                'Bevor Du dich für eine Reitbeteiligung entscheidest, solltest Du prüfen, ob Du bereit dafür bist. Damit eine Reitbeteiligung für alle Sinn macht, solltest Du bereits reiten können und mit den Grundlagen der Pferdepflege vertraut sein. Du solltest mindestens einmal pro Woche Zeit zum Reiten und für die Versorgung des Pferdes haben (häufig auch mehr) und zuverlässig sein. Absolute Termintreue, Ehrlichkeit und gute Kommunikationsfähigkeiten sind wichtige Eigenschaften einer guten Reitbeteiligung. Du solltest dir über die grosse Verantwortung, die eine Reitbeteiligung mit sich bringt, bewusst sein und immer das Beste für das Pferd im Sinn haben.\n' +
                '\n' +
                'Auch finanziell solltest Du dazu in der Lage sein, den monatlichen Beitrag pünktlich zu zahlen. Die Fähigkeit, Feedback anzunehmen und die Regeln der Besitzerin oder des Besitzers über deine eigenen zu stellen (zum Beispiel in Fütterungsfragen) sind weitere wichtige Voraussetzungen, um eine gute Reitbeteiligung zu sein.\n' +
                '\n' +
                'Weisst Du, dass Du deine Termine nicht wahrnehmen kannst, weil Du häufig unterwegs bist oder dir immer wieder spontan etwas dazwischenkommt, solltest Du dir das mit der Reitbeteiligung lieber noch einmal überlegen. Letztendlich entscheidet natürlich die Besitzerin oder der Besitzer des Pferdes, ob sie oder er dir seinen besten Freund anvertrauen möchte und dir die Aufgaben zutraut.\n' +
                '\n' +
                '<a href="https://www.horsedeal.com/blog">In unserem Blog</a> findest Du viele Artikel und Beiträge, die dir einen Einblick in den Ablauf einer Reitbeteiligung geben. So kannst Du besser entscheiden, ob Du der Aufgabe gewachsen bist. Sprich am besten auch mit Freunden, die ein eigenes Pferd haben oder selbst Reitbeteiligung sind, um einen genauen Einblick zu bekommen. Dadurch bist Du optimal auf deine Rolle als Reitbeteiligung vorbereitet und kannst damit starten, eine Reitbeteiligung zu finden.',
        },
    ];

    const accordionItems2 = [
        {
            title: 'Was muss man für eine Reitbeteiligung zahlen?',
            content:
                'Die Kosten für eine Reitbeteiligung variieren sehr stark. Je nach Pferderasse, Ausbildungsstand des Pferdes, Alter des Pferdes, zeitlicher Verfügbarkeit, übernommenen Aufgaben und sonstigen Rahmenbedingungen fallend die Kosten für eine Reitbeteiligung höher oder niedriger aus. Im Normalfall gilt, dass je mehr Aufgaben Du übernimmst (zum Beispiel Fütterung, Stallhygiene, Tierarztbesuche,…), je älter das Pferd ist und für reine Freizeitpferde die Kosten geringer ausfallen. Möchtest Du als Reitbeteiligung an Turnieren teilnehmen und ein leistungsstarkes Turnierpferd reiten, musst Du meist mit höheren Kosten rechnen. Normalerweise kannst Du jedoch mit 50 bis 500 Franken oder Euro pro Monat für eine Reitbeteiligung rechnen. Im Schnitt zahlen Reitbeteiligungen 150 bis 250 Franken oder Euro im Monat.\n' +
                '\n' +
                'Ob Kosten für Versicherungen, Tierarztrechnungen und die Ausrüstung dazukommen, solltest Du mit der Pferdebesitzerin oder dem Pferdebesitzer klären. Deswegen lohnt es sich, zu Beginn viele Fragen zu stellen und alles schriftlich festzuhalten. Die Kosten für deine eigene Ausrüstung trägst Du als Reitbeteiligung natürlich selbst.\n' +
                '\n' +
                'Auf jeden Fall kommst Du mit einer Reitbeteiligung weitaus günstiger weg, als mit einem eigenen Pferd. Auch für die Pferdebesitzer:innen bringt dieses Modell eine finanzielle und zeitliche Entlastung mit sich. Also eine Win-Winn Situation für alle!\n' +
                '\n' +
                'Auf unserer Plattform siehst Du genau, mit welchen monatlichen Kosten Du rechnen solltest und kannst so deine Suche schonmal eingrenzen. Zusätzlich schafft das viel Transparenz.',
        },
        {
            title: 'Welche Versicherung braucht man als Reitbeteiligung?',
            content:
                'Bei Versicherungen rund um die Reitbeteiligung gilt in der Regel: Die Besitzerin oder der Besitzer kümmert sich um die Versicherungen für das Pferd, wie zum Beispiel die Pferdekrankenversicherung und die Reitbeteiligung um ihre eigenen Versicherungen für die Zeit des Reitens oder die sie mit dem Pferd verbringt.\n' +
                '\n' +
                'Als Reitbeteiligung solltest Du deswegen auf jeden Fall eine Haftpflichtversicherung abschliessen. Diese kostet im Normalfall nicht die Welt und sichert dich im Fall der Fälle ab. Denn Missgeschicke und Unfälle passieren leider auch den besten Reiter:innen. Wenn Du beim Ausreiten beispielsweise ein Fahrzeug beschädigst, trägst Du als Reitbeteiligung auch die Verantwortung dafür.\n' +
                '\n' +
                'Deswegen solltest Du unbedingt auch an deine eigenen Versicherungen denken, bevor du eine Reitbeteiligung finden möchtest. Eventuell ist zusätzlich eine Unfallversicherung oder eine private Krankenversicherung das Richtige für dich. Lasse dich hierzu im Zweifel von den passenden Fachleuten beraten. In unserem Blogbeitrag stellen wir dir <a href="https://www.horsedeal.com/blog/die-richtige-pferdeversicherung-bei-einem-reitunfall">die wichtigsten Pferdeversicherungen</a> vor, wenn Du tiefer in das Thema einsteigen möchtest.\n' +
                '\n' +
                'Auf HorseDeal unterstützen wir dich ebenfalls bei dem Thema und haben in unseren Paketen die passenden Versicherungen gleich mit integriert. Damit Du sorgenfrei losreiten kannst und der ganze Papierkram leicht und übersichtlich geregelt ist.',
        },
        {
            title: 'Was sollte man bei einer Reitbeteiligung fragen?',
            content:
                'Bevor ihr euch zum Kennenlernen trefft, könnt ihr einige Fragen und Rahmenbedingungen bereits schriftlich klären. So geht ihr beide sicher, dass eure Zeit nicht verschwendet ist und ihr grundsätzlich zusammenpasst. Wichtige Punkte, die Du bereits vor einem Treffen abklären solltest sind unter anderem die Art der Reitbeteiligung, die zeitliche Verfügbarkeit, die Ausbildung des Pferdes und in welchem Rahmen Du das Pferd reiten darfst.\n' +
                '\n' +
                'Beim Kennenlerntreffen solltest Du dann alles fragen, was Du wissen möchtest. Umgekehrt solltest Du auch als Reitbeteiligung auf alle Fragen offen und ehrlich antworten. Nur so könnt ihr sichergehen, dass die Chemie stimmt und die Reitbeteiligung für alle ein Erfolg wird. Wichtige Fragen, die Du bei einer Reitbeteiligung stellen solltest, sind zum Beispiel:\n' +
                '<ul>' +
                '<li>Welche Arbeiten hat die Reitbeteiligung im Stall zu übernehmen? (beispielsweise Stallarbeiten)</li>' +
                '<li>In welchen Disziplinen darf das Pferd gearbeitet werden</li>' +
                '<li>Teilnahme an Veranstaltungen, zum Beispiel an Reitturnieren</li>' +
                '<li>Pflicht am Reitunterricht teilzunehmen? Wenn ja, wie oft und nur bei einem bestimmten Trainer?</li>' +
                '<li>Ob es eine Regelung gibt, sorgsam mit dem Equipment umzugehen und Schäden ggf. zu ersetzen</li>' +
                '<li>Reithelmpflicht</li>' +
                '<li>Sichtweisen (z. B. Leckerlis oder nicht)</li>' +
                '</ul>' +
                'Bei eurem Treffen oder danach solltet ihr dann auch einen Vertrag abschliessen, in dem unter anderem die folgenden Punkte enthalten sein sollten:\n' +
                '<ul>' +
                '<li>Die Vertragspartner (Besitzer:in des Pferdes und Reitbeteiligung) müssen erfasst werden (Name, Geburtsdatum, Adresse und Kontodaten)</li>' +
                '<li>Bei minderjährigen das schriftliche Einverständnis der Erziehungsberechtigten (d.h. Eltern) zwingend</li>' +
                '<li>Der Name und die Lebensnummer des Pferdes</li>' +
                '<li>Die Häufigkeit und die Wochentage, an denen das Pferd geritten werden darf</li>' +
                '<li>Die Höhe und Fälligkeit des monatlichen Beitrags oder aber die Übernahme bestimmter Posten (z. B. die Kosten für Futter)</li>' +
                '<li>Die Haftung für Schäden (die normale Abnutzung vom Equipment wird meist vom Besitzer übernommen, Schäden durch unsachgemässen Gebrauch Durch den Verursacher)</li>' +
                '<li>Die erforderlichen Versicherungen (Tierhalterhaftpflicht, Unfallversicherung)</li>' +
                '<li>Vertragsdauer und Kündigungsfrist</li>' +
                '</ul>' +
                'Auch eure im Voraus besprochenen Fragen könnt ihr im Vertrag natürlich schriftlich festhalten. Je mehr ihr hier regelt, desto besser. Bei HorseDeal stellen wir dir auch auf Anfrage einen Mustervertrag zur Verfügung.',
        },
        {
            tableOfContentTitle: 'Was sind Vor- und Nachteile zum eigenen Pferd?',
            title:
                'Welche Vor- und Nachteile hat eine Reitbeteiligung im Vergleich zum eigenem Pferd?',
            content:
                'Eine Reitbeteiligung hat viele Vorteile im Vergleich zum eigenen Pferd. Der wichtigste Vorteil ist sicherlich der finanzielle Aspekt. Ein eigenes Pferd ist für viele einfach nicht leistbar oder nur schwer zu stemmen. Eine Reitbeteiligung ist deutlich günstiger, Du trägst weniger Risiko und bekommst trotzdem fast das Gefühl eines eigenen Pferdes. Bei einer Reitbeteiligung hast Du ausserdem etwas mehr Flexibilität als bei einem eigenen Pferd und kannst unterschiedliche Pferderassen und Pferde kennenlernen, bevor Du dich für ein Traumpferd entscheidest. Du lernst den Umgang mit einem Pferd und sammelst viel Erfahrung, die dich später auf ein eventuelles eigenes Pferd vorbereiten. Auch für den Eigentümer des Pferdes bringt eine Reitbeteiligung zahlreiche Pluspunkte.\n' +
                '\n' +
                'Der einzige Nachteil bei einer Reitbeteiligung ist, dass Du meist nicht wann immer Du willst und spontan zum Pferd kannst, sondern einen Terminplan hast - obwohl es auch Reitbeteiligungen gibt, bei denen das nicht der Fall ist, zum Beispiel wenn die Besitzerin oder der Besitzer im Ausland lebt und selbst nicht reitet. Also: Alles eine Frage der Absprache und der optimalen Reitbeteiligung für dich zu finden.\n' +
                'In diesem Beitrag gehen wir genauer auf die <a href="https://www.horsedeal.com/blog/eigenes-pferd-oder-reitbeteiligung-das-sind-die-vor-und-nachteile">Vor- und Nachteile einer Reitbeteiligung im Vergleich zum eigenen Pferd</a> ein.',
        },
        {
            title: 'Welche Rechte und Pflichten habe ich als Reitbeteiligung?',
            content:
                'Als Reitbeteiligung behandelst Du das Pferd als wäre es dein eigenes. Du tust also alles, damit es ihm gut geht, putzt es, verbringst viel Zeit mit ihm und reitest es. Welche Aufgaben Du genau übernimmst, ist von Reitbeteiligung zu Reitbeteiligung verscheiden und wird direkt der Pferdebesitzerin oder dem Pferdebesitzer abgesprochen. Eine Reitbeteiligung ist eine lohnende und spassige Aufgabe, dennoch solltest Du die Verantwortung nie vergessen, die sie mit sich bringt. Als Reitbeteiligung musst Du zum Beispiel trotzdem auch in den Stall, wenn das Wetter schlecht ist oder Du mal gar nicht motiviert bist.\n' +
                '\n' +
                'Wird das Pferd während deiner Reitbeteiligung krank oder verletzt sich, kommt es zu einer Sondersituation. Hierfür habt ihr bestenfalls im Vertrag bereits die entsprechenden Regelungen getroffen, zum Beispiel ob deine Reitbeteiligung damit automatisch endet oder wie es mit deiner Beitragszahlung aussieht. Habt ihr keine Regelung für diesen Sonderfall getroffen, solltet ihr so bald wie möglich die offene Kommunikation suchen. Bei kleineren Verletzungen oder kürzeren Krankheiten zahlt die Reitbeteiligung im Normalfall ganz normal weiter und kümmert sich mit um die Pflege des Pferdes. Bei grösseren Verletzungen oder sollte das Pferd komplett reituntauglich werden, solltet ihr eine individuelle Absprache treffen.\n' +
                '\n' +
                'In unserem <a href="https://www.horsedeal.com/blog/reitbeteiligung-kodex">Reitbeteiligungskodex</a> gehen wir nochmal genauer auf deine Rechte und Pflichten als Reitbeteiligung ein.',
        },
        {
            tableOfContentTitle: 'Was tun bevor ich eine Reitbeteiligung eingehe?',
            title:
                'Wie kann ich sicherstellen, dass die Chemie zwischen mir und dem Pferd bzw. Besitzer:in stimmt, bevor ich mich für eine Reitbeteiligung entscheide?',
            content:
                'Damit eine Reitbeteiligung zum vollen Erfolg wird, sollte die Chemie zwischen dir, der Eigentümerin oder dem Eigentümer des Pferdes und dem Pferd stimmen. Ihr solltet euch gegenseitig sympathisch sein, euch voll und ganz vertrauen und offen und ehrlich kommunizieren können. Meist merkt man sehr schnell, ob alles passt und ihr auf einer Wellenlänge seid. Häufig bekommt man schon beim Schreiben ein erstes Gefühl und beim Kennenlernen könnt ihr euch dann genauer „beschnuppern“. Wichtig ist, im Vorhinein viele Fragen zu stellen und auf das Bauchgefühl zu hören.\n' +
                '\n' +
                'Im Idealfall reitest Du auch Probe, damit auch das Pferd dich kennenlernen kann und Du ein besseres Gefühl dafür bekommst, ob Du mit dem Pferd zurechtkommst. Sollte das nicht der Fall sein, ist das auch nicht schlimm, denn dafür sind die Kennenlerntermine da! Wichtig ist es, Bedenken und Probleme direkt anzusprechen. Lass dir beim Reitbeteiligung finden lieber etwas mehr Zeit, als dich Hals über Kopf hineinzustürzen und dann nach wenigen Wochen gleich wieder zu kündigen. Hier zeigen wir dir, <a href="https://www.horsedeal.com/blog/reitbeteiligung-worauf-du-unbedingt-achten-solltest">worauf Du bei einer Reitbeteiligung achten solltest</a>.',
        },
    ];

    return (
        <StaticPage
            title={title}
            schema={{
                '@context': 'https://schema.org',
                '@type': 'ReitbeteiligungFindenPage',
                description: intl.formatMessage({ id: 'SeoLandingPages.ridingShare.metaDescription' }),
                name: 'Reitbeteiligung finden page'
            }}
        >
            <LayoutSingleColumn>
                <LayoutWrapperTopbar>
                    <TopbarContainer />
                </LayoutWrapperTopbar>
                <LayoutWrapperMain
                    className={css.staticPageWrapper}
                    blobBackground
                    blobProps={{
                        xDistance: 20,
                        baseWidth: 760,
                    }}
                >
                    <Breadcrumb
                        paths={breadcrumbPaths}
                        urlPrefix={"/landing"}
                    />

                    <h1 className={css.pageTitle}>
                        <FormattedMessage
                            id="SeoLandingPages.ridingShare.Title"
                            defaultMessage="<highlight>Reitbeteiligungen finden</highlight> - Tipps {break}und Ticks für Reiter:innen"
                            values={{
                                break: <br className={css.pageTitleBreak} />,
                                highlight: (chunks) => <span className={css.pageTitleColor}>{chunks}</span>
                            }}
                        />
                    </h1>

                    <div className={css.coverWrapper}>
                        <div className={`${css.findPlacesNearby} ${css.flexItem}`}>
                            <h2 className={css.coverTitle}>
                                {intl.formatMessage({ id: 'SeoLandingPages.ridingShare.findPlacesNearby.Title' })}
                            </h2>
                            <p className={css.coverSubTitle}>
                                {intl.formatMessage({ id: 'SeoLandingPages.ridingShare.findPlacesNearby.SubTitle' })}
                            </p>
                            <div className={css.dumbInputRoot}>
                                <input
                                    type="text"
                                    className={css.dumbInput}
                                    placeholder={intl.formatMessage({ id: 'SeoLandingPages.findPlacesNearby.Placeholder' })}
                                    onFocus={() =>
                                        redirectToURLWithModalState(
                                            { location, history },
                                            isMobile ? 'mobilesearch' : 'desktopsearch',
                                        )
                                    }
                                />
                                <div className={css.dumbFieldIconHolder}>
                                    <MagnifyIcon />
                                </div>
                            </div>
                            <div className={css.reviewsIframe}>
                                <iframe name="text-banner-widget_frame"
                                        id="text-banner-widget_frame"
                                        frameBorder="0" width="100%" scrolling="no" height="66"
                                        title="Reviews Widget"
                                        src="https://widget.reviews.io/rich-snippet-reviews-widgets/widget?version=13b&amp;store=www.horsedeal.com&amp;starsClr=%23faba05&amp;textClr=%231a2b49&amp;logoClr=white&amp;widgetName=text-banner&amp;translateOurCustomersSay=%20&amp;translateOutOf5BasedOn=bei&amp;translateReviews=Bewertungen&amp;translateExcellent=Hervorragend&amp;css=.TextBanner%20.ReviewsLogo%20%7B%20display%3A%20none%20%7D&amp;elementId=text-banner-widget&amp"></iframe>
                            </div>
                        </div>
                        <div className={`${css.coverImageWrapper} ${css.flexItem}`}>
                            <img className={css.coverImage}
                                 src="https://horsedeal.imgix.net/static/landingPage/banner/reitbeteiligung-finden.webp"
                                 alt="Reitbeteiligungen finden" />
                        </div>
                    </div>

                    <div className={css.faq}>
                        <h2 className={css.contentHeading}>{intl.formatMessage({ id: 'SeoLandingPages.TableOfContents' })}</h2>
                        {generateAccordionTableOfContents([accordionItems1, accordionItems2])}
                    </div>

                    <AccordionItems items={accordionItems1} expandFirstElement={true} />

                    <HorseDealCreateAccountTeaser
                        title={intl.formatMessage({ id: 'SeoLandingPages.ridingShare.AccountTeaserTitle' })}
                        participationName={intl.formatMessage({ id: 'SeoLandingPages.ridingShare.AccountTeaserParticipationName' })}
                        currentUser={currentUser}
                        bounds={boundaries}
                        origin={loc}
                        listingType={listingTypeHorse}
                        pub_activities={pub_activities}
                    />

                    <ReviewsWidget className={css.reviewsioCarouselWidget} name={title} />

                    <AccordionItems items={accordionItems2} />

                    <div className={css.ridingSharesEuropeWrapper}>
                        <h2 className={css.ridingSharesEuropeTitle}>
                            <FormattedMessage
                                id="SeoLandingPages.ridingShares.Title"
                                values={{
                                    highlight: (chunks) => <span className={css.pageTitleColor}>{chunks}</span>
                                }}
                            />
                        </h2>
                        <p>{intl.formatMessage({ id: 'SeoLandingPages.ridingShares.SubTitle' })}</p>
                        <div className={css.ridingSharesEuropeInnerWrapper}>
                            <div className={css.ridingSharesCountry}>
                                <a href="/landing/reitbeteiligung-finden/deutschland" className={css.ridingSharesTitle}>
                                    {intl.formatMessage({ id: 'SeoLandingPages.ridingSharesCountry.DE' })}
                                </a>
                                <div
                                    className={css.ridingSharesCount}>{formatNumber(countHorsesDE)} {countHorsesDE === 1 ? 'Pferd' : 'Pferde'}</div>
                            </div>
                            <div className={css.ridingSharesCountry}>
                                <a href="/landing/reitbeteiligung-finden/schweiz" className={css.ridingSharesTitle}>
                                    {intl.formatMessage({ id: 'SeoLandingPages.ridingSharesCountry.CH' })}
                                </a>
                                <div
                                    className={css.ridingSharesCount}>{formatNumber(countHorsesCH)} {countHorsesCH === 1 ? 'Pferd' : 'Pferde'}</div>
                            </div>
                        </div>
                    </div>

                    <HorseDealAppTeaser />

                </LayoutWrapperMain>

                <LayoutWrapperFooter>
                    <Footer />
                </LayoutWrapperFooter>
            </LayoutSingleColumn>
        </StaticPage>
    );
};

ReitbeteiligungFindenPageComponent.defaultProps = {
    title: null,
    currentUser: null,
};

ReitbeteiligungFindenPageComponent.propTypes = {
    title: string,
    currentUser: propTypes.currentUser,
    intl: intlShape.isRequired
};

const mapStateToProps = state => ({
    currentUser: state.user.currentUser,
});

const ReitbeteiligungFindenPage = compose(
    connect(mapStateToProps),
    withRouter,
    injectIntl
)(ReitbeteiligungFindenPageComponent);

export default ReitbeteiligungFindenPage;
